import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d786f232 = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _3c5900cd = () => interopDefault(import('../pages/courses/_slug.vue' /* webpackChunkName: "pages/courses/_slug" */))
const _90c7d768 = () => interopDefault(import('../pages/docs/_slug.vue' /* webpackChunkName: "pages/docs/_slug" */))
const _98173ca8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _d786f232,
    name: "about"
  }, {
    path: "/courses/:slug?",
    component: _3c5900cd,
    name: "courses-slug"
  }, {
    path: "/docs/:slug?",
    component: _90c7d768,
    name: "docs-slug"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
